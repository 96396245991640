import './App.css'

// ? Router
import { Route, Routes } from 'react-router-dom'

// ? Custom components
import NavBar from '../../components/NavBar/NavBar'
import HomePage from '../HomePage/HomePage'
import AboutPage from '../AboutPage/AboutPage'
import SkillsPage from '../SkillsPage/SkillsPage';
import ProjectsPage from '../ProjectsPage/ProjectsPage'
import ContactPage from '../ContactPage/ContactPage'
import ExperiencePage from '../ExperiencePage/ExperiencePage';
import Footer from '../../components/Footer/Footer'

export default function App() {

  return (
    <main className="App">
      <>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/skills" element={<SkillsPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/experience" element={<ExperiencePage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        <Footer />
      </>
    </main>
  );
}