import './SkillsPage.css'

import { Badge } from 'reactstrap'

// ! React Icons
// ? Skills
import { BiLogoHtml5 } from 'react-icons/bi'
import { BiLogoCss3 } from 'react-icons/bi'
import { BiLogoJavascript } from 'react-icons/bi'

import { BiLogoGithub } from 'react-icons/bi'
import { TbBrandVscode } from 'react-icons/tb'

import { BiLogoTrello } from 'react-icons/bi'
import { BiLogoFigma } from 'react-icons/bi'

import { SiPostman } from 'react-icons/si'
import { BiLogoHeroku } from 'react-icons/bi'

import { BiLogoSass } from 'react-icons/bi'
import { BiLogoBootstrap } from 'react-icons/bi'
import { DiMaterializecss } from 'react-icons/di'

import { BiLogoMongodb } from 'react-icons/bi'
import { SiMongoose } from 'react-icons/si'
import { SiExpress } from 'react-icons/si'
import { BiLogoReact } from 'react-icons/bi'
import { BiLogoNodejs } from 'react-icons/bi'

import { BiLogoPython } from 'react-icons/bi'
import { BiLogoDjango } from 'react-icons/bi'
import { BiLogoPostgresql } from 'react-icons/bi'

export default function SkillsPage() {

  return (
    <div className="skills-container">
      <h1 className='skills-title heading-underline'>Skills</h1>
      <div className='container'>
        <div className='row'>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className="d-flex align-items-center flex-column mx-2 mb-2 ">
              <BiLogoHtml5 className="skill mb-1" />
              <Badge pill>HTML</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoCss3 className="skill mb-1" />
              <Badge pill>CSS</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoJavascript className="skill mb-1" />
              <Badge pill>JavaScript</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoPython className="skill mb-1" />
              <Badge pill>Python</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoMongodb className="skill mb-1" />
              <Badge pill>MongoDB</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <SiExpress className="skill mb-1" />
              <Badge pill>Express</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoReact className="skill mb-1" />
              <Badge pill>React</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoNodejs className="skill mb-1" />
              <Badge pill>Node.js</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <SiMongoose className="skill mb-1" />
              <Badge pill>Mongoose</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoDjango className="skill mb-1" />
              <Badge pill>Django</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoPostgresql className="skill mb-1" />
              <Badge pill>PostgreSQL</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoGithub className="skill mb-1" />
              <Badge pill>GitHub</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <TbBrandVscode className="skill mb-1" />
              <Badge pill>VS Code</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoBootstrap className="skill mb-1" />
              <Badge pill>Bootstrap</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <DiMaterializecss className="skill mb-1" />
              <Badge pill>Materialize</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoTrello className="skill mb-1" />
              <Badge pill>Trello</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoFigma className="skill mb-1" />
              <Badge pill>Figma</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoSass className="skill mb-1" />
              <Badge pill>Sass</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <SiPostman className="skill mb-1" />
              <Badge pill>Postman</Badge>
            </span>
          </div>
          <div className="all-skills col-md-2 col-sm-3 col-4">
            <span className='d-flex align-items-center flex-column mx-2 mb-2'>
              <BiLogoHeroku className="skill mb-1" />
              <Badge pill>Heroku</Badge>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}