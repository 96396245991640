import './AboutPage.css'

export default function AboutPage() {

  return (
    <>
      <div className='about-page'>
        <h1 className='heading-underline'>About Me</h1>
        <div className='container'>
          As a <span className='purple-text'>former financial services professional</span> turned <span className='purple-text'>software engineer</span>, I bring a unique perspective shaped by my experience in adjudication and remediation.
        </div>
        <br/>
        <div className='container'>
          What inspired me to pursue this change and complete General Assembly's software engineering bootcamp was the opportunity to combine my passions for <span className='purple-text'>problem-solving</span> and technology with my commitment to making a <span className='purple-text'>positive impact</span> on others and the planet.
        </div>
        <br/>
        <div className='container'>
          My professional values of integrity, determination and collaboration inspire my work.
          I'm eager to harness my <span className='purple-text'>analytical mindset</span> and <span className='purple-text'>strong communication</span> skills to develop robust and user-friendly software solutions as a <span className='purple-text'>full stack developer</span>.
        </div>
        <br/>
        <div className='container'>
          I'm confident that my <span className='purple-text'>ability to adapt</span> quickly, <span className='purple-text'>collaborate</span> effectively and <span className='purple-text'>continuously learn</span> will enable me to thrive in the dynamic and ever-evolving field of software development.
        </div>
        <br />
        <div className='container'>
          <span className='purple-text'> When not at my desk</span>, you'll find me enjoying the outdoors, spending time with friends and family, working out, trying new food, playing video games, travelling or enjoying sports.
        </div>
        <br/>
      </div>
    </>
  )
}