import './ProjectsPage.css'
import { Badge } from 'reactstrap'

import amFitDark from '../../assets/AmFit-dark.png'
import amFitLight from '../../assets/AmFit-light.png'
import itinerEaseDark from '../../assets/ItinerEase-dark.png'
import itinerEaseLight from '../../assets/ItinerEase-light.png'
import spaceCatLight from '../../assets/SpaceCAT-light.png'
import spaceCatDark from '../../assets/SpaceCAT-dark.png'
import saiyanInvadersDark from '../../assets/Saiyan-Invaders-dark.png'
import saiyanInvadersLight from '../../assets/Saiyan-Invaders-light.png'
import eurosDark from '../../assets/euros-dark.png'
import eurosLight from '../../assets/euros-light.png'

import { FaExternalLinkSquareAlt } from 'react-icons/fa'
import { FaGithubSquare } from 'react-icons/fa'

export default function ProjectsPage({ darkMode }) {

  return (
    <>
      <div className='projects-page'>
        <div className='container pb-4'>
          <h1 className='heading-underline mb-5'>Projects</h1>
          <div className='project-5 mb-4'>
            <div className='row'>
              <div className='title-and-image col-md-6 col-12'>
                <h2>Euro 2024 Sweepstake</h2>
                <img src={darkMode ? eurosDark : eurosLight} alt="Euro 2024 Sweepstake" />
              </div>
              <div className='desc-and-skills mt-4 col-md-6 col-12'>
                <div className='project-description'>
                  A single-page application for generating a sweepstake for Euro 2024, using JavaScript and React. 
                  Includes the ability to save the results as a CSV file and a light and dark theme.
                </div>
                <div className='project-skills mt-2'>
                  <Badge pill className='mx-1'>React</Badge>
                  <Badge pill className='mx-1'>JavaScript</Badge>
                  <Badge pill className='mx-1'>HTML</Badge>
                  <Badge pill className='mx-1 mt-2'>CSS</Badge>
                </div>
                <div className='links-to-site mt-2'>
                  <a
                    href="https://euro-2024-sweepstake-generator-54a796d01a5b.herokuapp.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                    aria-label="Visit Euro 2024 Sweepstake application"
                  >
                    <FaExternalLinkSquareAlt />
                  </a>
                  <a
                    href="https://github.com/AmritpalC/euros-sweepstake"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                    aria-label="GitHub repository for Euro 2024 Sweepstake application"
                  >
                    <FaGithubSquare />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className='project-4 mb-4'>
            <div className='row'>
              <div className='title-and-image col-md-6 col-12'>
                <h2>ItinerEase</h2>
                <img src={darkMode ? itinerEaseDark : itinerEaseLight} alt="ItinerEase" />
              </div>
              <div className='desc-and-skills mt-4 col-md-6 col-12'>
                <div className='project-description'>
                  A full stack, single-page application for itinerary planning, using MongoDB, Express, React and Node. 
                  The app also consumes data from the Google Maps and Places API. 
                  Includes a light and dark theme to add to a seamless user experience when planning for your next holiday.
                </div>
                <div className='project-skills mt-2'>
                  <Badge pill className='mx-1'>MongoDB</Badge>
                  <Badge pill className='mx-1'>Express</Badge>
                  <Badge pill className='mx-1'>React</Badge>
                  <Badge pill className='mx-1 mt-2'>Node.js</Badge>
                </div>
                <div className='links-to-site mt-2'>
                  <a
                    href="https://itinerease-a1bfd538ff22.herokuapp.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                    aria-label="Visit ItinerEase application"
                  >
                    <FaExternalLinkSquareAlt />
                  </a>
                  <a
                    href="https://github.com/AmritpalC/ItinerEase"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                    aria-label="GitHub repository for ItinerEase"
                  >
                    <FaGithubSquare />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div className='project-3 mb-4'>
            <div className='row'>
              <div className='title-and-image col-md-6 col-12'>
                <h2>SpaceCAT</h2>
                <img src={darkMode ? spaceCatDark : spaceCatLight} alt="SpaceCAT" />
              </div>
              <div className='desc-and-skills mt-4 col-md-6 col-12'>
                <div className='project-description'>
                  App which lets you explore NASA's Astronomy Picture of the Day API, with the ability to save photos and create albums.
                  Worked in a group of three to build the app, using Python, Django and PostgreSQL.
                  I built the bulk of our back-end code and specific pages for the site, including full CRUD functionality. Helped style the app so that it has a friendly and responsive user interface.
                </div>
                <div className='project-skills mt-2'>
                  <Badge pill className='mx-1'>Python</Badge>
                  <Badge pill className='mx-1'>Django</Badge>
                  <Badge pill className='mx-1 mt-2'>PostreSQL</Badge>
                </div>
                <div className='links-to-site mt-2'>
                  <a
                    href="https://space-cat.fly.dev/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                    aria-label="Visit SpaceCAT application"
                  >
                    <FaExternalLinkSquareAlt />
                  </a>
                  <a
                    href="https://github.com/AmritpalC/SpaceCAT"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                    aria-label="GitHub repository for SpaceCAT"
                  >
                    <FaGithubSquare />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div className='project-2 mb-4'>
            <div className='row'>
              <div className='title-and-image col-md-6 col-12'>
                <h2>AmFit</h2>
                <img src={darkMode ? amFitDark : amFitLight} alt="AmFit" />
              </div>
              <div className='desc-and-skills mt-4 col-md-6 col-12'>
                <div className='project-description'>
                  Workout planning app, built using Node.js, Express with a MongoDB Atlas Database. Includes Google Authentication and Authorisation (OAuth 2.0).
                </div>
                <div className='project-skills mt-2'>
                  <Badge pill className='mx-1'>Node.js</Badge>
                  <Badge pill className='mx-1'>Express</Badge>
                  <Badge pill className='mx-1 mt-2'>MongoDB</Badge>
                </div>
                <div className='links-to-site mt-2'>
                  <a
                    href="https://am-fit.fly.dev/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                    aria-label="Visit AmFit application"
                  >
                    <FaExternalLinkSquareAlt />
                  </a>
                  <a
                    href="https://github.com/AmritpalC/AmFit"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                    aria-label="GitHub repository for AmFit"
                  >
                    <FaGithubSquare />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div className='project-1 mb-4'>
            <div className='row'>
              <div className='title-and-image col-md-6 col-12'>
                <h2>Saiyan Invaders</h2>
                <img src={darkMode ? saiyanInvadersDark : saiyanInvadersLight} alt="Saiyan Invaders" />
              </div>
              <div className='desc-and-skills mt-4 col-md-6 col-12'>
                <div className='project-description'>
                  A Dragonball Z parody of the classic Space Invaders game, made using HTML, CSS and vanilla JavaScript.
                </div>
                <div className='project-skills mt-2'>
                  <Badge pill className='mx-1'>HTML</Badge>
                  <Badge pill className='mx-1'>CSS</Badge>
                  <Badge pill className='mx-1 mt-2'>JavaScript</Badge>
                </div>
                <div className='links-to-site mt-2'>
                  <a
                    href="https://amritpalc.github.io/Space-Invaders/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                    aria-label="Visit Saiyan Invaders application"
                  >
                    <FaExternalLinkSquareAlt />
                  </a>
                  <a
                    href="https://github.com/AmritpalC/Space-Invaders"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                    aria-label="GitHub repository for Saiyan Invaders"
                  >
                    <FaGithubSquare />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}