import './Footer.css'

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <span>&copy; 2024. Designed and built by Amritpal Chahal</span>
      </footer>
    </>
  )
}