import './HomePage.css'
import { Link } from 'react-router-dom'
import AboutPage from '../AboutPage/AboutPage'
import SkillsPage from '../SkillsPage/SkillsPage'
import ProjectsPage from '../ProjectsPage/ProjectsPage'
import ExperiencePage from '../ExperiencePage/ExperiencePage'
import ContactPage from '../ContactPage/ContactPage'
import { useGlitch } from 'react-powerglitch'

// ? Contact
import { BsLinkedin } from 'react-icons/bs'
import { BsGithub } from 'react-icons/bs'
import { BiMailSend } from 'react-icons/bi'
import { TbSquareRoundedArrowDownFilled } from 'react-icons/tb'

export default function HomePage() {

  const glitch = useGlitch({
    "playMode": "hover",
    "createContainers": true,
    "hideOverflow": true,
    "timing": {
      "duration": 500,
      "iterations": 1,
    },
    "glitchTimeSpan": {
      "start": 0,
      "end": 1
    },
    "shake": {
      "velocity": 10,
      "amplitudeX": 0.2,
      "amplitudeY": 0.2
    },
    "slice": {
      "count": 6,
      "velocity": 10,
      "minHeight": 0.02,
      "maxHeight": 0.15,
      "hueRotate": true
    },
    "pulse": false
  })

  const scrollToTop = () => {
    document.body.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <div className="home-page">
        <div className='title-container row align-items-center'>
          <div className='title-section col-xs-12 col-sm-10 col-md-8 offset-sm-1 offset-md-2'>
            <h1 className="name" ref={glitch.ref}>Amritpal Chahal</h1>
            <h3 className="title">Junior Full Stack Developer</h3>
            <div className="buttons-container mb-5">
              <Link to="/about" onClick={scrollToTop} className="mx-3 about-me-button">About Me</Link>
              <Link to="/contact" onClick={scrollToTop} className="mx-3 contact-button">Contact</Link>
            </div>
          </div>
          <div className='contact-section col-md-2'>
            <ul className='contact-icons'>
              <li>
                <a
                  href="https://www.linkedin.com/in/amritpal-chahal/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-icon"
                  alt="LinkedIn"
                >
                  <BsLinkedin />
                </a>
              </li>
              <li>
                <a
                  href="mailto:amritpalchahal@hotmail.co.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-icon"
                  alt="Email"
                >
                  <BiMailSend />
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/AmritpalC"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-icon"
                  alt="GitHub"
                >
                  <BsGithub />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='scrollers'>
          <div className='scroll-down'>
            <div className='mouse'>
              <div className='scroller'>
              </div>
            </div>
          </div>
          <div className='scroll-down-arrow'>
            <TbSquareRoundedArrowDownFilled />
          </div>
        </div>
      </div>
      <AboutPage />
      <SkillsPage />
      <ProjectsPage />
      <ExperiencePage />
      <ContactPage />
    </>
  )
}