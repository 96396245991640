import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import './NavBar.css'
import { TbHexagonLetterA } from 'react-icons/tb'
import { TbHexagonLetterC } from 'react-icons/tb'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import { Sling as Hamburger } from 'hamburger-react'

import { BsMoonFill } from 'react-icons/bs'
import { BsSunFill } from 'react-icons/bs'

export default function NavBar() {

  const [isOpen, setOpen] = useState(false)
  const [darkMode, setDarkMode] = useState(true) 

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [darkMode])

  const toggleDarkMode = () => { setDarkMode(!darkMode) }

  const closeNavBar = () => { setOpen(false) }

  const scrollToTop = () => {
    document.body.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleNavLinkClick = () => {
    closeNavBar()
    scrollToTop()
  }

  return (
    <div>
      <div>
        <Navbar className="navbar-expand-md fixed-top">
          <NavbarBrand className="me-auto">
            <Link to="/" onClick={handleNavLinkClick} className="nav-name" >
              <TbHexagonLetterA />
              <TbHexagonLetterC className='letter-c' />
            </Link>
          </NavbarBrand>
          <NavbarBrand className="theme-toggle" id="theme-small-screen" onClick={toggleDarkMode} >
            {darkMode ? <BsSunFill /> : <BsMoonFill />}
          </NavbarBrand>
          <NavbarToggler>
            <Hamburger
              rounded
              duration={0.7}
              label="Show menu"
              toggled={isOpen}
              toggle={setOpen}
              className="me-2" />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar tint-color='true' >
            <Nav navbar className='ms-auto'>
              <NavItem>
                <NavLink>
                  <Link to="about" onClick={handleNavLinkClick} className='navbar-link' >About</Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link to="skills" onClick={handleNavLinkClick} className='navbar-link' >Skills</Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link to="projects" onClick={handleNavLinkClick} className='navbar-link' >Projects</Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link to="experience" onClick={handleNavLinkClick} className='navbar-link' >Experience</Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link to="contact" onClick={handleNavLinkClick} className='navbar-link' >Contact</Link>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
          <NavbarBrand className="theme-toggle" id="theme-large-screen" onClick={toggleDarkMode} >
            {darkMode ? <BsSunFill /> : <BsMoonFill />}
          </NavbarBrand>
        </Navbar>
      </div>
    </div>
  )  
}