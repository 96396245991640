import './ContactPage.css'

import { BsLinkedin } from 'react-icons/bs'
import { BsGithub } from 'react-icons/bs'
import { BiMailSend } from 'react-icons/bi'

import profPic from '../../assets/Profile-picture-portfolio.jpg'

export default function ContactPage() {

  return (
    <>
      <div className='contact-page'>
        <div className='container'>
          <h1 className='heading-underline'>Contact</h1>
          <div className='profile-pic mt-4'>
            <img src={ profPic } alt="" />
          </div>
          <div className='contact-text mt-4'>Feel free to contact me regarding any enquiries, job opportunities or just to say hi!</div>
          <div className='contact-options'>
            <div className='linkedIn'>
              <a
                href="https://www.linkedin.com/in/amritpal-chahal/"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-icon"
                alt="LinkedIn"
              >
                <BsLinkedin />
              </a>
              <h5>LinkedIn</h5>
            </div>
            <div className='email'>
              <a
                href="mailto:amritpalchahal@hotmail.co.uk"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-icon"
                alt="Email"
              >
                <BiMailSend />
              </a>
              <h5>Email</h5>
            </div>
            <div className='gitHub'>
              <a
                href="https://github.com/AmritpalC"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-icon"
                alt="GitHub"
              >
                <BsGithub />
              </a>
              <h5>GitHub</h5>
            </div>
          </div>      
        </div>
      </div>
    </>
  )
}