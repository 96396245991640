import './ExperiencePage.css'

export default function ExperiencePage() {

  return (
    <>
      <div className='experience-page'>
        <h1 className='heading-underline'>Experience</h1>
        <div className='container'>
          <ul className='work-history-list'>
            <li className='mt-4'>
              <div className='employer-name'>
                <div>General Assembly</div>
              </div>
              <div className='role-and-dates'>
                <span>Student - Software Engineering Immersive</span>
                <span className='role-dates'>Apr 2023 - July 2023</span>
              </div>
            </li>
            <li className='mt-4'>
              <div className='employer-name'>
                <div>KPMG</div>
              </div>
              <div className='role-and-dates'>
                <span>Quality Assurance & Policy and Process Specialist</span>
                <span className='role-dates'>Jan 2022 - Apr 2023</span>
              </div>
              <div className='role-and-dates'>
                <span>Investigator</span>
                <span className='role-dates'>Aug 2021 - Jan 2022</span>
              </div>
            </li>
            <li className='mt-4'>
              <div className='employer-name'>
                <div>Financial Ombudsman Service</div>
              </div>
              <div className='role-and-dates'>
                <span>Subject Matter Expert</span>
                <span className='role-dates'>Jul 2018 - Jul 2021</span>
              </div>
              <div className='role-and-dates'>
                <span>Adjudicator</span>
                <span className='role-dates'>Dec 2016 - Jul 2018</span>
              </div>
            </li>
            <li className='mt-4'>
              <div className='employer-name'>
                <div>Deloitte</div>
              </div>
              <div className='role-and-dates'>
                <span>Case Handler</span>
                <span className='role-dates'>Jan 2016 - Nov 2016</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}